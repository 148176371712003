<template>
    <section class="content">
			<div class="container-fluid">
				<div class="row">
          <custom-alert v-if="displayAlert==true" :message="alertMessage"
                        v-on:showedAlert="resetAlert"></custom-alert>
					          <section v-if="indexPage" class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3 text-center">
						<div class="card">
							<div class="card-body table-responsive table-head hvertical">
                                <div class="row bor-bot">
                                    <div class="col-md-12 pl-0">
                                        <h1 class="m-0 text-dark pt-2 pb-3 text-left">Create Email Template</h1>
                                    </div>
                                </div>
                                <div class="d-flex justcontcenter">
                                    <div class="createtemplate">
                                        <img src="/svg/waterski/email.svg"/>
                                        <h6 class="m-0 pt-3 pb-4 fnt-bold"><i>Currently you don't have any Email Template</i></h6>
                                        <button type="button" class="btn darkblubtn btn-outline-primary" @click="showAddTemplate()">Create Email Template</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                     <section  v-if="showAddTemplates" class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
						<div class="card">
							<div class="card-body table-responsive table-head">
                                <div class="row">
                                    <div class="col-md-12 pl-0">
                                        <h1 class="m-0 text-dark pt-2 pb-3 text-left">{{ this.title }}</h1>
                                    </div>
                                </div>
                                <div class="emiltempform shadowbox">
                                            <div class="row">
                                            <div class="col-md-2 mt-3">
                                                <label>Template Type</label>
                                            </div>
                                            <div class="col-md-10 mt-3 temptype">
                                                <select v-model="form.type_id" class="form-control">
                                                    <option disabled value="">Select</option>
                                                    <option  v-for="(option,r) in templateTypes"  :key="r" :value="option.id">
                                                      {{ option.type }}
                                                    </option>
                                                </select>
                                              <p v-if="this.errors.typeid" class="error_color">{{ this.errors.typeid}}</p>
                                            </div>
                                            <div class="col-md-2 mt-3">
                                                <label>Template Name</label>
                                            </div>
                                            <div class="col-md-10 mt-3 tempname">
                                                <input class="form-control" maxLength="160" v-model="form.name"/>
                                            <p v-if="this.errors.name" class="error_color">{{ this.errors.name}}</p>
                                            <p v-if="exist" class="error_color">{{ exist }}</p>
                                            </div>
                                            <div class="col-md-2 mt-3">
                                                <label>Subject</label>
                                            </div>
                                            <div class="col-md-10 mt-3">
                                                <input class="form-control" maxLength="160" v-model="form.subject" />
                                               <p v-if="this.errors.subject" class="error_color">{{ this.errors.subject}}</p>
                                            </div>
                                            <div class="col-md-2 mt-3">
                                                <label>Message</label>
                                            </div>
                                            <div class="col-md-10 mt-3">
                                                <ckeditor v-model="form.message"></ckeditor>
                                                <!-- <vue-editor v-model="form.message"></vue-editor> -->
                                                <p class="pt-2 pb-0 mb-0"><b>Available Tags:</b> [First Name] [Last Name] [Email] [Member ID] [Team Name]</p>
                                                
                                                <p v-if="this.errors.message" class="error_color">{{ this.errors.message}}</p>
                    
                                            </div>
                                            <div class="col-md-12 text-right mt-3">
                                              <button type="submit"  @click="skip()" class="btn btn-secondary mr-3">Cancel</button>
                                              <button type="submit" v-if="this.title === 'Edit Email Template'"  :disabled="updatDisable" @click="updatEmailTemplate(form)" class="btn btn-primary mr-3">Save</button>
                                                <button type="submit" v-if="this.title != 'Edit Email Template'" :disabled="saveDisable" @click="onSubmit()" class="btn btn-primary mr-3">Save</button>
                                                <button type="submit" v-if="this.title != 'Edit Email Template'" :disabled="saveDisable" @click="onSubmit('new')" class="btn btn-primary">Save & Add New</button>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </section>
                     <section v-if="addNew" class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3 text-center">
						<div class="card">
							<div class="card-body table-responsive table-head hvertical">
                                <div class="justcontcenter">
                                    <div class="createtemplate shadowbox">
                                        <img src="/svg/waterski/emailsuccess.svg"/>
                                        <h6 class="m-0 pt-3 pb-4 fnt-bold"><i>Created Successfully</i></h6>
                                        <button type="button" @click="skip()" class="btn darkblubtn btn-outline-primary mr-3 smbtn">Skip</button>
                                        <button type="button" @click="addNewSection()" class="btn darkblubtn btn-outline-primary smbtn">Add New</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
          <section v-if="list" class="col-lg-12 connectedSortable mt-3 pr-0 pl-0 mb-3 text-center">
            <div class="card">
              <div class="card-body table-responsive table-head hvertical">


                <div class="row">
                  <div class="col-md-8 text-left px-0 bor-bot pb-3">
                    <h1 class="m-0 text-dark pt-2 text-left px-0">Email Templates</h1>
                  </div>
                  <div class="col-md-4 bor-bot pt-1 pr-0">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right pr-0">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item"><Breadcrumbs/></li>
                        </ol>
                      </div>
                  </div>

                  
                    <div class="col-md-6 mt-3 showentries"> 
                      <b-form inline>
                        <label class="mr-sm-2 font-weight-normal">Show</label>
                        <b-form-select
                            id="inline-form-custom-select-pref"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            v-model="perPage"
                            :options="viewEntryOptions"
                            size="sm"
                        ></b-form-select
                        >entries
                      </b-form>
                    </div> 
                    <div class="col-md-6">
                      <div class="mt-3 mb-3 text-right pr-0 emltmpserchsec">
                          <div class=" card-text d-flex align-items-center float-right mb-3">
                          <div class="mr-4">
                            <b-input-group size="sm">
                              <b-form-input
                                  id="filter-input"
                                  v-model="filter"
                                  type="search"
                                  placeholder="Search"
                              ></b-form-input>
                            </b-input-group>
                          </div>

                          <b-button
                              variant="btn darkblubtn outline-primary "
                              @click="showAddTemplate($event.target)">Add Email Template</b-button>
                        </div>
                        </div>
                    </div>
                  


                  <b-table
                      ref="member-typeTable"
                      id="emailteplatlist"
                      :items="items"
                      :fields="fields"
                      :filter="filter"
                      :per-page="perPage"
                      :filter-included-fields="filterOn"
                      :current-page="currentPage"
                      @filtered="onFiltered"
                      class="mt-1"
                      hover
                      show-empty
                  >
                    <template #cell(actions)="row">
                      <div class="text-left py-0 align-middle">
                        <div class="btn-group btn-group-sm">
                          <button
                              class="btn btn-primary ml-1"
                              @click="editRow(row.item, $event.target)"
                          >
                            <i class="fas fa-pencil-alt" title="Edit"></i>
                          </button>
                          <button
                              class="btn btn-danger ml-1"
                              @click="deleteRow(row.item.id)"
                          >
                            <i class="fas fa-trash" title="Delete"></i>
                          </button>
                        </div>
                      </div>
                    </template>
                    <template #empty="scope">
                      <h4>{{ scope.emptyText }}</h4>
                    </template>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                  </b-table>

                </div>

                <div class="row">
                  <div class="col-md-12 text-right">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        prev-text="Previous"
                        next-text="Next"
                        aria-controls="merchandise-table"
                        class="float-right custom-color"
                        hide-goto-end-buttons
                    ></b-pagination>
                  </div>
                </div>

              </div>
            </div>
          </section>
            </div>




          </div>

    </section>
</template>

<style>
.error_color{
  color:red;
}
</style>
<script>

// import { VueEditor } from "vue2-editor";
 import axios from "axios";
import CustomAlert from "../CustomAlert.vue";

const validateSelect = select => {
  if (select == "" || select == null) {
    return { valid: false, error: "Template Type is required" };
  }
  return { valid: true, error: null };
};
const validateFirstName = (name) => {
  if (name ==  undefined || name.length <= 0) {
    return { valid: false, error: "Template Name is required" };
  }
    return { valid: true, error: null };

};
const validateSubject = (subject) => {
  if (subject ==  undefined || subject.length <= 0) {
    return { valid: false, error: "Subject is required" };
  }
    return { valid: true, error: null };
};
const validateMessage = (msg) => {
  if (msg ==  undefined || msg.length <= 0) {
    return { valid: false, error: "Message is required" };
  }
    return { valid: true, error: null };
};



export default {
  components: {
    // VueEditor,
    CustomAlert,
    // CKEditor
  },

  data() {

    return {
       type_id: "1",
        form:{
          type_id:'',
          name:'',
          subject:'',
          message:'',
          application_id:1,
          organization_id:1
        },
      addNew:false,
      showAddTemplates:false,
      indexPage:false,
      templateTypes:'',
      editModal: {
        editing: true,
        id: "edit-modal",
        title: "",
        content: "",
      },
      list:false,
      items: [],
      fields: [
        {
          key: "name",
          label: "Template Name",
          sortable: true,
        },
        {
          key: "template_type.type",
          label: "Template Type Name",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      filter: null,
      templateList:'',
      perPage: 10,
      filterOn: [],
      viewEntryOptions: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 20, text: "20" },
      ],
      loaded: false,
      currentPage: 1,
      totalItems:0,
      saveDisable:false,
      updatDisable:false,
      exist:''
    };
  },
  beforeMount() {
    this.getEmailTemplateTypes();
    this.getTemplateTypes();
  },
  methods:{
    getTemplateTypes(e) {
      console.log(this.form)
      axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL+ 'api/templateType/getTemplateType',{},{headers: {
          'application-id': process.env.VUE_APP_APPLICATION_ID,
          'Content-Type': 'application/json',
        }})
          .then((res) => {
            console.log('getTemplateTypes',res);
            this.templateTypes = res.data.templateType;
            console.log(this.templateTypes);
          })
          .catch((error) => {
            console.log('Error:'+ error);
            return false;
          })
    },
    getEmailTemplateTypes(e) {
      axios.get(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/emailtemplate',{headers: {
          'application-id': process.env.VUE_APP_APPLICATION_ID,
          'Content-Type': 'application/json',
        }})
          .then((res) => {
            this.items = res.data.data;
            console.log(this.items != '')
            if(this.items != ''){
              console.log('true')
              this.list = true,
              this.indexPage = false
            } else{
              console.log('else')
              this.list = false,
              this.indexPage = true
            }
          })
          .catch((error) => {
            console.log('Error:'+ error);
            return false;
          })
    },
    onSubmit(e) {
      if(!this.validate()){
        return false;
      }
        console.log(this.form)
        axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/emailTemplateCreate',this.form,{headers: {
            'application-id': process.env.VUE_APP_APPLICATION_ID,
            'Content-Type': 'application/json',
          }})
            .then((res) => {
              this.saveDisable = true
              console.log(res);
              if(e == 'new'){
                // console.log('neewww')
                this.showAddTemplates =false;
                this.$bvModal.show('addNew')
                this.addNew = true;
              }
              else{
                this.addNew = false;
                this.displayAlert = true
                this.alertMessage = res.data.message;
                this.getEmailTemplateTypes();
                this.showAddTemplates = false;
                this.list = true;
              }

            })
            .catch((error) => {
             if(error.response.data.errors){
              this.exist = error.response.data.errors.name;
              this.exist = error.response.data.errors.type_id;
             }
             else{
              this.exist = error.response.data.message;
             }
              //console.log(error.response.data.errors.name)
              
            })


    },
    showAddTemplate(){
      this.exist = ''
      this.title = 'Create Email Template'
      this.saveDisable = false
      this.form.type_id='',
      this.form.name='',
      this.form.subject ='',
      this.form.message ='',
      this.indexPage = false
      this.list = false
      this.showAddTemplates = true;
    },
    skip(){
      this.exist = ''
      this.form.type_id='',
      this.form.name='',
      this.form.subject ='',
      this.form.message ='',
      this.addNew = false;
      this.showAddTemplates = false;
      this.indexPage = false;
      this.getEmailTemplateTypes();
    },
    addNewSection(){
      this.exist = ''
      this.saveDisable = false
      this.addNew = false;
      this.form.type_id='',
      this.form.name='',
      this.form.subject ='',
      this.form.message ='',
      this.form.application_id =1,
      this.form.organization_id =1
      this.showAddTemplates = true;
    },
    validate() {
      this.errors = {}
      this.valid = false;
      console.log('nameeeee',this.form.name)

      const validSelect = validateSelect(this.form.type_id);
      this.errors.typeid = validSelect.error;
      this.valid = validSelect.valid;

      const validFirstName = validateFirstName(this.form.name);
      this.errors.name = validFirstName.error;
      this.valid = validFirstName.valid;

      const validSubject = validateSubject(this.form.subject);
      this.errors.subject = validSubject.error;
      this.valid = validSubject.valid;

      const validMessage= validateMessage(this.form.message);
      this.errors.message = validMessage.error;
      this.valid = validMessage.valid;

      return this.valid;
    },
    editRow(item) {
      this.exist = ''
      this.updatDisable = false
      this.list = false;
      this.showAddTemplates = true;
      this.title = 'Edit Email Template';
      this.form.type_id = item.type_id;
      this.form.name =  item.name,
      this.form.subject = item.subject,
      this.form.message = item.message,
      this.form.id = item.id
    },
    deleteRow(id) {
      if(confirm("Are you sure to delete this item?")){
        axios
            .delete( process.env.VUE_APP_EMAIL_CAMPAIGN_URL + "api/emailTemplate/" + id,{headers: {
                'application-id': process.env.VUE_APP_APPLICATION_ID,
                'Content-Type': 'application/json',
              }})
            .then((response) => {
              this.displayAlert = true
              this.alertMessage = response.data.message;
              this.getEmailTemplateTypes();
            })
            .catch((error) => {
              (error) => (this.errors = error.response.data);
            });
        return false;
      }

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatEmailTemplate(data) {
      
      if(!this.validate()){
        return false;
      }
      axios.patch(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/emailTemplateUpdate/' + data.id ,data,{headers: {
          'application-id': process.env.VUE_APP_APPLICATION_ID,
          'Content-Type': 'application/json',
        }})
          .then((res) => {
            this.updatDisable = true
            console.log(res)
            this.displayAlert = true
            this.alertMessage = res.data.message;
            this.showAddTemplates = false
            this.list = true,
            this.getEmailTemplateTypes();
          })
          .catch((error) => {
            if(error.response.data.errors){
              this.exist = error.response.data.errors.name;
              this.exist = error.response.data.errors.type_id;
             }
             else{
              this.exist = error.response.data.message;
             }
          })
    },
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },

};
</script>
